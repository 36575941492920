import axios from "axios"
import { Message } from "element-ui"

axios.defaults.baseURL = 'http://47.108.228.177:8080'

let isShowMsg = false

//统一所有接口请求的基础地址
// axios.defaults.baseURL = "http://localhost:8082"

//响应拦截器，在接口返回时拦截返回值。我们可以对这个返回值进行修改，修改后再传递给调用接口位置。
axios.interceptors.response.use((resp) => {
    //resp就是接口返回的值
    return resp.data
}, (error) => {
    if (error && error.response && error.response.status === 401) {
        console.log(error)
        if (!isShowMsg) {
            isShowMsg = true
            Message({
                type: 'error',
                message: "登录超时，请重新登录",
                onClose: () => {
                    isShowMsg = false
                    location = '/'
                }
            })
            return {
                code: 500,
                data: {},
                msg: '登录超时，请重新登录'
            }
        }
    }
})

// 请求拦截器，在接口发送请求之前，调用配置好的拦截器函数，并且可以修改参数
axios.interceptors.request.use(req => {

    // let loginUser = JSON.parse(sessionStorage.getItem("loginUser"))
    // if (loginUser) {
    //     //已登录的情况
    //     req.headers.token = loginUser.token
    // } else {
    //     console.log("未检测到token")
    // }

    let user = localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")) : {}//获取登录时存放的user对象信息
    req.headers['token'] = user.token  // 设置请求头
    return req
}, error => {
    return Promise.reject(error)
})

export default {
    post (url, params) {
        return axios({
            url,
            method: 'post',
            data: params
        })
    },
    get (url, params) {
        return axios({
            url,
            method: 'get',
            params
        })
    }
}